import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useLocation, HashRouter as Router } from "react-router-dom";
import './index.css';
import App from './pages/App';
// import MyComponent from './Components/dashboard';
import reportWebVitals from './reportWebVitals';
import RoutesManager from './routes'

import loader from "./loader.gif"


function RApp() {
  const [isParent,setIsParent] = useState(false);
  const [isMenuLoaded, setIsMenuLoaded] = useState(false)
 

  
  return (
   
    <React.StrictMode>
      <Router>
        <RoutesManager />
      </Router>
    </React.StrictMode>
  );
}

ReactDOM.render(
  
    <RApp />
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
