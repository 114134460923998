import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import './passwordLess.css'

export const PasswordLessLogin=(props)=>{

   

    const [disableButton,setDisableButton]=useState(false);

    useEffect(()=>{
        if(disableButton===true){
            setTimeout(()=>{
                setDisableButton(false)
            },5000)
        }
    },[disableButton])

    const handleEmailChange=(e)=>{
        props.setShowWarning(false);
        props.setEmail(e.target.value)
        setDisableButton(false)
    }

    const handleEmailEnter=(e)=>{
        if(e.keyCode==13){
            handleEmailClick()
        }
    }

    const handleEmailClick=()=>{
        console.log("clicked button");
        let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
        if(regex.test(props.email)){
            setDisableButton(true);
            props.setIsLoading(true);
            props.handlePasswordlessSignIn()
        }else{
            props.setWarning("Enter Valid Email")
            props.setShowWarning(true);
        }
    }

    return(<div style={{marginBottom:"8px"}} >
        {/* <div style={{marginBottom:"8px",display:'flex'}}>
        <Typography style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "19.36px",
                    color:"rgba(0, 0, 0, 1)"
                    }}>Email</Typography>
        </div> */}
        <div >
        <div className={props.warning?'error':'custom'} style={{display:"flex"}}>
        <div>
        <TextField
          id="outlined-required"
          value={props.email}
          style={{width:'350px',borderRadius:"5px",borderColor:"rgba(0, 0, 0, 0.23)"}}
          onKeyDown={e=>handleEmailEnter(e)}
          onChange={e=>handleEmailChange(e)}
          placeholder='Your work email here'
        />
        </div>
        <div style={{marginLeft:"8px"}}>
            <button onClick={handleEmailClick} disabled={disableButton}  style={{width:"43px",height:"42px", borderColor:"rgba(33, 218, 140, 1)"}}><ArrowForwardIosRoundedIcon style={{color:"rgba(33, 218, 140, 1)"}} /></button>
        </div>
        </div>
        {props.showWarning && <div style={{display:'flex',marginTop:"3px"}}>
            <ErrorOutlineRoundedIcon  style={{color:'rgba(245, 88, 67, 1)',width:"18px",height:"18px"}} />
            <Typography variant='body2' style={{
                    fontWeight: "600",
                    fontSize: "14px",
                    fontFamily:"Inter",
                    lineHeight: "20.02px",
                    color:"rgba(245, 88, 67, 1)",
                    marginLeft:"4px"
                    }}>{props.warning}</Typography>
        </div>}
        </div>
        {/* <button 
        disabled={disableButton}
        style={{width:"400px",height:"48px",backgroundColor:"rgba(33, 218, 140, 1)",color:"rgba(255, 255, 255, 1)",borderRadius:"5px",cursor:'pointer'}} 
        onClick={handleEmailClick} 
        >
        <Typography style={{
                    fontWeight: "800",
                    fontSize: "15px",
                    fontFamily:"Inter",
                    lineHeight: "26px",
                    color:"white",
                    Letter:'0.46px',
                    marginLeft:"4px"
                    }}>SEND AUTHENTICATION CODE</Typography>
        
        </button> */}
       </div>)

    
}