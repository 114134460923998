import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import './App.css';
import GsuiteLogin from '../Components/GsuiteLogin';
import CircularProgress from '@material-ui/core/CircularProgress';
function App(props) { 
  
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const history = useHistory();

  useEffect(() => {
    checkAuth();
  }, []);


const getLastLoginDate=async()=>{
    const timeZone=Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log(timeZone);
    fetch(`${window._env_.AUTH_URL}/users/get-last-login`, {
        method: "GET",
        credentials: "include",
      })
        .then((res) => res.json())
        .then((loginData) => { 
          if (loginData.success) {
            const time=new Date((typeof loginData.data === "string" ? new Date(loginData.data) : loginData.data).toLocaleString("en-US", {timeZone: timeZone})); 
            console.log("time",time.toString())
            props.setLastLoginTime(time.toString())
          }
          else{
            console.log(loginData.error);
          }
        });
  }
  function checkAuth() {
    fetch(`${window._env_.AUTH_URL}/authorize/validate-token`, { 
      method: 'GET', 
      credentials: "include",
    }).then(res => res.json())
    .then(json => {
      setIsAuthChecked(true);
      if (json.success) {
            getLastLoginDate()
            props.getNotifications()
            history.push('/home');
      } else {
        history.push('/');
      }
    })
    .catch(err => {
        setIsAuthChecked(true);
        console.log(err);
    })
  }

  return (
    <div className="App">
      
      {
        isAuthChecked ? (
          <GsuiteLogin  getNotifications={ props.getNotifications} tokenSetter={props.tokenSetter} setIsAuth={props.setIsAuth}  setLastLoginTime={props.setLastLoginTime}/>
        ) : <div style={{position:"absolute",top:"50%",left:"50%",translate:"transform(-50%,-50%)",}}><CircularProgress style={{color:"#21DA9C"}} /></div>
      }
      
</div>
      
  );
}

export default App;