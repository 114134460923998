import React, { useState,useEffect } from 'react';
import OtpInput from 'react-otp-input';
import './otp.css'
import { Typography } from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';


export const OtpTextFeild=(props)=>{
    const [otp, setOtp] = useState('');
    const [buttonText,setButtonText]=useState("RESEND CODE IN 30 SECONDS");
    const [enableResendButton,setEnableResendButton]=useState(false);
    const [showButton,setShowButton]=useState(true);

    useEffect(()=>{
        setTimeout(()=>{
            setButtonText("RESEND CODE")
            setEnableResendButton(true)
        },30000)
    },[])

    const handleButtonClick=()=>{
        setButtonText("RESEND CODE IN 30 SECONDS");
        setEnableResendButton(false);
        setShowButton(false)
        props.handlePasswordlessSignIn()
    }

    const handleChange=(otp)=>{
        console.log("clicked",otp);
        props.setShowOtpError(false);
        if(otp?.length===6){
            if(props.validateOtp===true){
                props.handlePasswordlessLogin(props.email,otp)
                props.setValidateOtp(false)
                props.setIsLoading(true);
            }
        }
        setOtp(otp);
        
    }

  return (
    <div className='otp' style={{marginTop:'60px',marginLeft:'25px'}}>
    <div style={{height:"19px",width:"389px",marginTop:"24px",display:'inline'}}>
    <Typography style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "19.36px",
                    color:"rgba(0, 0, 0, 1)"
                    }}>{`Please enter the code sent to ${props.email}`}<EditRoundedIcon  onClick={e=>props.setShowotp(false)} style={{color:"rgba(3, 3, 3, 0.6)",width:'17px',cursor:'pointer',position:'absolute'}} /></Typography>
                    </div>
    <div className={props.showOtpError?'error':'safe'} style={{marginTop:"24px"}} >
    <OtpInput
      value={otp}
      onChange={handleChange}
      focusStyles={{borderColor:"green"}}
      numInputs={6}
      renderSeparator={<span></span>}
      renderInput={(props) => <input style={{boxShadow:'none'}} {...props} />}
    />
    {props.showOtpError && <div style={{display:'flex',marginLeft:"17px"}}> <Typography style={{
                    fontWeight: "600",
                    fontSize: "14px",
                    fontFamily:"Inter",
                    lineHeight: "20.02px",
                    color:"rgba(245, 88, 67, 1)",
                    letterSpacing:"1px"
                    }}>{props.otpError}</Typography></div> }
    
    </div>
    {showButton && <div style={{marginTop:"24px",width:"430px",height:"48px",padding:"0px 12px 6px 12px"}}>
        <button className={enableResendButton?"enable":'disable'} style={{width:"232px",height:"30px",backgroundColor:"white",border:'none'}}>
        <Typography style={{
                    fontWeight: "800",
                    fontSize: "13px",
                    lineHeight: "22px",
                    letterSpacing:"0.46px",
                    }}
                    onClick={handleButtonClick}
                    >{buttonText}</Typography></button>
    </div> }   
                
    </div>
    
  );
}