import React, { useState } from 'react';
import { loginRequest } from '../authConfig';
import {  useMsal } from '@azure/msal-react';
import msIcon from "../media/msIcon.png"
import { useHistory } from "react-router-dom";

export const MicrosoftLoginButton=(props)=>{
 
  // const [gotAuthCookie,setGotAuthCookie]=useState(false);
  const history = useHistory();
  const { instance } = useMsal();

  const enableRouterNavigation=()=>{
    history.block(null);
  }

  const handleLogin = async(loginType) => {
    console.log("clicked",loginType);
    if (loginType === "popup") {
      try {
        const loginResponse = await instance.ssoSilent(loginRequest);
        // setGotAuthCookie(true)
          enableRouterNavigation();
        props.authHandler(null,loginResponse);
    } catch (err) {
        console.log("microsoft error",err)
          try {
            const loginResponse = await instance.loginPopup(loginRequest);
            // setGotAuthCookie(true)
          enableRouterNavigation();
            props.authHandler(null,loginResponse);
          } catch (error) {
            console.log("microsoft error",err);
            props.authHandler(error,null);
          }
    }
    } 
  };

    const disableRouterNavigation=()=>{
      history.block((location, action) => {
        return false;
      });
    }

    const openAuthPopup=async(loginType)=>{
      disableRouterNavigation()
      // setTimeout(handleLogin(loginType),500);
      await handleLogin(loginType)
    }

    return(
        <>
        <div onClick={()=>openAuthPopup("popup") }>
        <button style={{height:"42px"}} >
                      <img
                      src={msIcon}
                      alt="google"
                      height="25"
                      style={{
                        paddingRight:"10px",
                        width: "18px",
                        height: "18px",
                      }}
                      
            />
            <div className="buttonText" >Sign in with Microsoft</div>
        </button>
        </div>
      {/* {gotAuthCookie &&   <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
          <p>Redirecting to home page</p>
        </div>} */}
        </>
    )
}