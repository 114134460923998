import React, { useEffect } from 'react';
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import Alert from "@mui/material/Alert";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dropdown from './dropdown';
import AttributesHeader from './attributeSetUpHeader';
import "../Header/toast.css"
import AttributesTable from './attributesTable';
import {Button} from "@material-ui/core";
import {Loader, NoResultFound} from './FallBackComponenets';
import "./styles.css";
import _uniq from 'lodash/uniq';

import { NoAccess } from '../NoAccess';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '24px',
    backgroundColor:"#F9F9F9"
  },
  button: {
    
    "&:disabled": {
      backgroundColor: "#B6BAB4"
    },
    // backgroundColor: "#016B40",
  },
}));

toast.configure({
  autoClose: 6000,
  draggable: true,
  hideProgressBar: true,
  position: toast.POSITION.BOTTOM_LEFT,
  icon: true,
  newestOnTop: true,
});

export default function ControlledAccordions() {
  const classes = useStyles();
  const tenantId = localStorage.getItem("b2bTenantId");

  const [isActivities, setIsActivities] = React.useState(false);
  const [loading, setLoading] = React.useState(false)
  const isSubAttrTrueArr = []
  const [accountsDropDown, setAccountsDropDown] = React.useState([]);
  const [contactsDropDown, setContactsDropDown] = React.useState([]);
  const [leadsDropDown, setLeadsDropDown] = React.useState([]);
  const [opportunitiesDropDown, setOpportunitiesDropDown] = React.useState([]);
  const [sourcesDropDown, setSourcesDropDown] = React.useState([]);
  const [activityTypeDropDown, setActivityTypeDropDown] = React.useState([]);
  const [attrDropDown, setAttrDropDown] = React.useState([]);
  const [mapperMappings, setMapperMappings] = React.useState({});
  const [mapperTableMappings, setMapperTableMappings] = React.useState({});
  const [isSourceSelected, setIsSourceSelected] = React.useState(false);
  const [isActivityTypeSelected, setIsActivityTypeSelected] = React.useState(false);
  const [isOtherCategory, setIsOtherCategory] = React.useState(false);
  const [inp, setInpBox] = React.useState("");
  const [body, setBody] = React.useState({Category:"", Source:"", ActivityType:"", SubAttribute:"", Attribute:""});
  const [sourceScreenVal, setSourceScreenVal] = React.useState(false);
  const [typeScreenVal, setTypeScreenVal] = React.useState(false);
  const [subAttrVal, setSubAttrVal] = React.useState(false);
  const [attrVal, setAttrVal] = React.useState(false);
  const [attrType, setAttrType] = React.useState(false);
  const [newVal,setNewVal] = React.useState([]);
  const [isSubAttrTrue,setIsSubAttrTrue] = React.useState(isSubAttrTrueArr);
  const [subAttrObj, setSubAttrObj] = React.useState({});
  const [reset,setReset] = React.useState(false);

  let [valuesCheck, setValuesCheck] = React.useState(false);
  const mappings = {'Accounts':{}, 'Contacts':{}, 'Leads':{}, 'Opportunities':{}};
  const tableMappings = {'Accounts':{}, 'Contacts':{}, 'Leads':{}, 'Opportunities':{}};

  
  const [rows, setRows] = React.useState([])

  let permissions = JSON.parse(localStorage.getItem("b2Permissions")) || [];
  const permissionCheck=()=>{
      const permissionsOfUser = permissions.map((obj)=>obj.resourceName.toLowerCase());
      if(permissionsOfUser.includes("attribute_setup")){
        return true;
      }
      return false;
  }

  useEffect(() => {
    if(isActivities === false){
      setIsSourceSelected(false);
      setIsActivityTypeSelected(false);
    }
  },
   [isActivities]
  )

  const fetchMapper = async (screen,type) => {
    try {
      const excludedAttributes = ['accper|opportunityName','accounts|labelName', 'contacts|labelName','departmentPersonalevel','accounts|activityType','contacts|activityType','icpsOfClosedOppsCrossSell','accper|tagged_icps','opportunities|tagged_icps','accounts|activityAttributes.campaignName','contacts|activityAttributes.campaignName','productsOfLOpps','stagesOfLOpps','accountName','accountWebsite','firstName','lastName','email','accounts|scoringAttributes.webpageurl','contacts|scoringAttributes.webpageurl']
      const attributesArr = [];
      const tenantConfigResponse = await fetch(`${window._env_.TENANT_CONFIG_API}/tenant/field/mapper/get/${tenantId}/${screen}`, { credentials: "include" });
      const mappings_data = await tenantConfigResponse.json();

      console.log("mappings_data->",Object.keys(mappings_data));
      for(let keys in mappings_data){
        if(mappings_data[keys]?.["isFilter"]?.toString() === 'true' || mappings_data[keys]?.["isSubAttribute"]?.toString() === 'true' || mappings_data[keys]?.["filter"]?.toString() === 'true'){
          if(!(['text','picklist','default'].includes(mappings_data[keys]?.filterType?.toLowerCase())) && screen !== 'leadHub-creation'){
              continue;
          }

          if(mappings_data[keys]?.label && mappings_data[keys]?.label != "") {
            if(!excludedAttributes.includes(mappings_data[keys]))
              attributesArr.push(mappings_data[keys]?.label );
            mappings[type][mappings_data[keys]?.label] = keys;
            tableMappings[type][mappings_data[keys]?.label] = mappings_data?.[keys]?.tableName;
            if(mappings_data[keys]?.isSubAttribute){
              isSubAttrTrueArr.push(keys)
            }
          }else{
            const labelVal = extractValue(keys)
            if(!excludedAttributes.includes(mappings_data[keys]))
              attributesArr.push(labelVal?.charAt(0)?.toUpperCase() + labelVal?.slice(1))
            mappings[type][labelVal?.charAt(0)?.toUpperCase() + labelVal?.slice(1)] = keys;
            tableMappings[type][labelVal?.charAt(0)?.toUpperCase() + labelVal?.slice(1)] = mappings_data?.[keys]?.tableName;
            if(mappings_data[keys]?.isSubAttribute){
              isSubAttrTrueArr.push(keys)
            }
          } 
        
        }
       
      }
      console.log("attributesArr-screen->",screen);
      console.log("isSubAttrTrueArr->",isSubAttrTrueArr);
      setIsSubAttrTrue([...isSubAttrTrue, ...isSubAttrTrueArr])
      setMapperMappings(mappings);
      setMapperTableMappings(tableMappings);
      switch(screen){
        case 'unified-profile-ui' : setAccountsDropDown(attributesArr);
        break;
        case 'ContactHub-creation': setContactsDropDown(attributesArr);
        break;
        case 'leadHub-creation': setLeadsDropDown(attributesArr);
        break;
        case 'opportunity-hub': setOpportunitiesDropDown(attributesArr);
        break;
        
      }
      console.log("mappings->",mappings);

    }
    catch (err) {
      console.log("error", err);
    }
  }

  function extractValue(line) {
    let parts = line.split('.');
    if (parts.length > 1 && parts[1] !== '') {
        return parts[1];
    }
    parts = line.split('|');
    if (parts.length > 1) {
        return parts[1]; 
    }
    return line;
}


  async function getSubAttributeConfig() {
    const tenantId = localStorage.getItem('b2bTenantId');
    const options = {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tenantId: tenantId,
        tool: 'filters',
        keys: ['subfiltersConfig'],
      }),
    };
    fetch(`${window._env_.TENANT_CONFIG_API}/tenant/get-tenant-config-json`, options)
      .then((res) => res.json())
      .then((result) => {
        let val = result?.data?.configs?.[0]?.configVal;
        console.log("Val-val",sourceScreenVal,typeScreenVal,val);
        val = val['accounts|activityType'][sourceScreenVal][typeScreenVal];
        const newObj = {};
        console.log("isSubAttrTrue length->",isSubAttrTrue?.length);
        val.forEach((ele) => {
          if(isSubAttrTrue.length && isSubAttrTrue.includes(ele)){
            let att = extractValue(ele);
            att = att?.charAt(0)?.toUpperCase() + att?.slice(1);
            newObj[att] = ele;
          }
          
        });
        console.log("newObj-->",newObj);
        setSubAttrObj(newObj);
        // const attributeArr = [];
        // for(let str of newObj){
        //   console.log("str -> ",str);
        //   attributeArr.push(extractValue(str))
        // }
        setAttrDropDown(Object.keys(newObj));
        
      })
      .catch((e) => {
        console.error('dashboard url error', e);

      });
  }

  async function fetchSources(){
    const options = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tenantId: tenantId,
        tool: 'MAP',
        keys: ["Tools"],
      }),
    };
      fetch(`${window._env_.TENANT_CONFIG_API}/tenant/get-tenant-config-json`, options)
    .then((res) => res.json())
    .then((result) => {
      console.log("result-",result.data.configs[0].configVal);
      const configVal = result.data.configs[0].configVal;
      const typeArr = [];
      for(let val in configVal){
        typeArr.push(configVal[val]?.tool)
      }
      setSourcesDropDown(typeArr);
    })
    .catch((e) => {
      console.error("Error in fetchSources", tenantId, e);

    });
  }

  useEffect(() => {
    fetchMapper('unified-profile-ui', 'Accounts');
    fetchMapper('ContactHub-creation', 'Contacts');
    fetchMapper('leadHub-creation', 'Leads');
    fetchMapper('opportunity-hub', 'Opportunities');
  },[])

  async function populateCategories(attrType){
    console.log("in populateCategories");
    switch(attrType){
      case 'Activities': await fetchSources();
      break;

    }
    
  }

  function populateActivityTypes(){
    console.log("sourceScreenVal type->",sourceScreenVal?.toUpperCase())
    const options = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tenantId: tenantId,
        tool: sourceScreenVal,
        keys: ["b2ActivitiesMapping"],
      }),
    };
      fetch(`${window._env_.TENANT_CONFIG_API}/tenant/get-tenant-config-json`, options)
    .then((res) => res.json())
    .then((result) => {
      console.log("result-",result.data.configs[0].configVal);
      const configVal = result.data.configs[0].configVal;
      let typeArr = [];
      for(let val in configVal){
        typeArr.push(configVal?.[val]?.b2ActivityType)
      }
      typeArr = _uniq(typeArr);
      setActivityTypeDropDown(typeArr);
    })
    .catch((e) => {
      console.error("Error in fetchSources", tenantId, e);

    }); 
  }

  useEffect(async() => {
    console.log("attrType->",attrType);
    await populateCategories(attrType);
    console.log("mapperTableMappings?.[attrType]?.[attrVal] ->",mapperTableMappings?.[attrType]?.[attrVal] );
    setBody({"Category":mapperTableMappings?.[attrType]?.[attrVal] ||  attrType, "Source": sourceScreenVal, "ActivityType":typeScreenVal, "SubAttribute":subAttrVal,
            "Attribute":attrType ? mapperMappings?.[attrType]?.[attrVal] : false});
    if(sourceScreenVal){
      populateActivityTypes();
      getSubAttributeConfig();
    }
    console.log("body inside the use-effect",subAttrVal);
 
    console.log("refreshEnable->",mapperMappings, mapperMappings[attrType]);
    if(subAttrVal != false || attrVal !=false){
      //call API
      setLoading(true);
      console.log("call API")
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" ,
        "Cookie" : localStorage.getItem("b2bToken")
      },
        body: JSON.stringify({
          "tableName": mapperTableMappings?.[attrType]?.[attrVal] || body.Category ,
          "columnName":subAttrVal ? subAttrVal : mapperMappings?.[attrType]?.[attrVal],
          "source":body.Source,
          "activityType":body.ActivityType,
          
        }),
        credentials: "include",
      };

      fetch(`${window._env_.METADATA_API}/attribute-setup/v2/getAttributeDataForSetupUI/${localStorage.getItem('b2bTenantId')}`,
       requestOptions)
      .then((response) => 
      
        response.json()
      )
      .then((val) => {
        console.log("json-",val)
        setRows(val?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
    }
  },
   [sourceScreenVal,typeScreenVal,subAttrVal,attrVal, attrType]
  )


  function addAttribute () {
    setValuesCheck(false);
    if(inp != ""){
      const alreadyPresent = rows.filter((ele) => ele?.attributeValue?.toLowerCase() === inp?.toLowerCase());
      if(!alreadyPresent.length){
        rows.splice(0,0,{
          attributeValue : inp,
          userGenerated : true,
          updatedAt: new Date().toISOString(),
          enabled : true
        })
  
        console.log("newArr length", rows?.length);
        setInpBox("");
        setRows(rows);
  
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" ,
          "Cookie" : localStorage.getItem("b2bToken")
        },
          body: JSON.stringify({
            "tableName":body.Category,
            "columnName":subAttrVal ? subAttrVal : mapperMappings?.[attrType]?.[attrVal],
            "source":body.Source,
            "activityType":body.ActivityType,
            "attributeValue":inp
          }),
          credentials: "include",
        };
  
        fetch(`${window._env_.METADATA_API}/attribute-setup/v2/newAttributeData/${localStorage.getItem('b2bTenantId')}`,
         requestOptions)
        .then((response) => 
        
          response.json()
        )
        .then((val) => {
          console.log("json-",val);
        })
        .catch((err) => {
          console.error(err);
        });
        
        toast.success(<Alert severity="success">{"New Attribute Value Successfully added!"}</Alert>, {
          closeOnClick: true,
          autoClose: true,
          closeButton: <CloseIcon style={{ width:"18px"}}/>,
          newestOnTop: true,
        }); 
      }
      
    }
  }

  function checkForButtonEnable(inp){
    const check = rows.filter((ele) => ele?.attributeValue?.toLowerCase() === inp?.toLowerCase());
    console.log("check->",check, inp.length);
    if(subAttrVal || attrVal){
      if(check.length){
        setValuesCheck(false)
      }else {
        if(!inp.length)
          setValuesCheck(false);
        else
          setValuesCheck(true);
      }
    }else setValuesCheck(false);

  }


  return (
    <>{permissionCheck()? <div className={classes.root} style={{minHeight: "100vh", 
    }}>
    <AttributesHeader attrType={attrType} body={body} tenantId = {localStorage.getItem('b2bTenantId')}/>
    
    
    <div style={{ marginTop: "24px", display:'flex', flexDirection:"row", flexWrap:"wrap",marginLeft:"48px"}}>
      <Dropdown dropdownLabel='Category' style={{ padding: "6px 6px 6px 0"}} 
      setIsActivities={setIsActivities} setIsOtherCategory={setIsOtherCategory} parentDropDown={true}
       isDisabled={true} 
       setBody = {setBody} body={body}
       setAttrType = {setAttrType}
       CATEGORIES = {["Accounts", "Contacts", "Leads", "Opportunities", "Activities"]}
      label = {'Attribute Category'}
      setRows = {setRows}
      setAttrVal = {setAttrVal}
      setSubAttrVal = {setSubAttrVal}
      setSourceScreenVal = {setSourceScreenVal}
       />
      {
        isActivities ? 
         <p style={{ display:'flex', flexDirection:"row", flexWrap:"wrap"}}>
          {/* <p style={{ paddingLeft: "32px" }}> */}
          <Dropdown
            style={{ marginLeft: "60px",paddingLeft: "32px" }}
            dropdownLabel={isActivities ? "Select Source" : "Select Attributes"}
            screenVal = {"Source"}
            setIsSourceSelected = {setIsSourceSelected}
            setIsActivities={setIsActivities} 
            parentDropDown={false}
            setSourceScreenVal = {setSourceScreenVal}
            isDisabled={isActivities}
            isActivities = {isActivities}
            setBody = {setBody} body={body}
            CATEGORIES = {sourcesDropDown}
            label = {'Source'}
            setRows = {setRows}
            setTypeScreenVal = {setTypeScreenVal}
            setSubAttrVal = {setSubAttrVal}
            isOtherCategory = {false}
            setReset = {setReset}
          />
        {/* <p style={{ paddingLeft: "32px" }}> */}
          <Dropdown
            style={{ marginLeft: "60px" ,paddingLeft: "32px"}}
            dropdownLabel="Select Activity Type"
            sourceScreenVal = {sourceScreenVal}
            setTypeScreenVal = {setTypeScreenVal}
            screenVal = {"Type"}
            setIsActivityTypeSelected = {setIsActivityTypeSelected}
            parentDropDown={false}
            isDisabled = {isSourceSelected} //true ->show false ->hide
            isSourceSelected = {isSourceSelected}
            isOtherCategory = {false}
            setBody = {setBody} body={body}
            CATEGORIES = {activityTypeDropDown}
            label = {'Activity Type'}
            setRows = {setRows}
            setSubAttrVal = {setSubAttrVal}
            typeScreenVal = {typeScreenVal}
            setReset = {setReset}
          /> 
        
        {/* </p> */}
        {/* <p style={{ paddingLeft: "32px" }}> */}
                <Dropdown
                style={{ marginLeft: "60px",paddingLeft: "32px" }}
                dropdownLabel="Select Sub-attribute"
                isOtherCategory = {false}
                typeScreenVal = {typeScreenVal}
                subAttrVal = {subAttrVal}
                sourceScreenVal = {sourceScreenVal}
                screenVal = {"SubAttributes"}
                setSubAttrVal = {setSubAttrVal}
                parentDropDown={false}
                isDisabled={isActivityTypeSelected}
                isActivityTypeSelected = {isActivityTypeSelected}
                setBody = {setBody} body={body}
                CATEGORIES = {attrDropDown}
                subAttrObj = {subAttrObj}
                label = {'Sub-attribute'}
                reset = {reset}
                setReset = {setReset}
              />
              
          {/* </p> */}
        </p>  
        : 
        attrType === 'Accounts' ? 
        <Dropdown
        style={{ marginLeft: "60px" }}
        dropdownLabel="Select Attribute"
        screenVal = {"Attributes"}
        parentDropDown={false}
        isDisabled={true}
        setAttrVal = {setAttrVal}
        setBody = {setBody} body={body}
        attrType = {attrType}
        CATEGORIES = {accountsDropDown}
        label = {'Attribute'}
        attrVal = {attrVal}
        isOtherCategory = {true}
      />      
      :
        attrType === 'Contacts' ? 
        <Dropdown
        style={{ marginLeft: "60px" }}
        dropdownLabel="Select Attribute"
        screenVal = {"Attributes"}
        parentDropDown={false}
        isDisabled={true}
        setAttrVal = {setAttrVal}
        setBody = {setBody} body={body}
        attrType = {attrType}
        CATEGORIES = {contactsDropDown}
        label = {'Attribute'}
        attrVal = {attrVal}
        isOtherCategory = {true}
      />  
      : 
        attrType === 'Leads' ? 
        <Dropdown
        style={{ marginLeft: "60px" }}
        dropdownLabel="Select Attribute"
        screenVal = {"Attributes"}
        parentDropDown={false}
        isDisabled={true}
        setAttrVal = {setAttrVal}
        setBody = {setBody} body={body}
        attrType = {attrType}
        CATEGORIES = {leadsDropDown}
        label = {'Attribute'}
        attrVal = {attrVal}
        isOtherCategory = {true}
      />
      : 
      attrType === 'Opportunities' ? 
        <Dropdown
        style={{ marginLeft: "60px" }}
        dropdownLabel="Select Attribute"
        screenVal = {"Attributes"}
        parentDropDown={false}
        isDisabled={true}
        setAttrVal = {setAttrVal}
        setBody = {setBody} body={body}
        attrType = {attrType}
        CATEGORIES = {opportunitiesDropDown}
        label = {'Attribute'}
        attrVal = {attrVal}
        isOtherCategory = {true}
      />     
      : 
      <></> 
      }

    </div>
    
    
    {isActivities || isOtherCategory ?
      
      <div style={{ marginTop: "32px", padding: "6px 6px 6px 10px",marginLeft:"48px"}}>

          <Typography
            style={{
              fontFamily: "Inter",
              fontWeight: '500',
              fontSize: '12px',
              lineHeight: '14.52px',
              color:"#000000DE"
            }}
          >
            Add Attribute Value
          </Typography>
          
        <div style={{marginTop:"8px"}} className = {classes.button}>
        <TextField
            style={{width:"250px", borderRadius:"8px", border:"1px", backgroundColor:"#ffffff"}}
            id="outlined-basic"
            placeholder="Enter Value"
            // style={{ margin: "10px" }}
            disabled={subAttrVal || attrVal ? false : true}
            className={classes.textField}
            value={inp}
            onChange={(e) => {
              setInpBox(e.target.value);
              e.target.value != "" ? checkForButtonEnable(e.target.value) : checkForButtonEnable(e.target.value);
              setNewVal(rows.filter(item => 
                item.attributeValue.toLowerCase().includes(e.target.value.toLowerCase()))
              )
            }}
            variant="outlined"
          />
          <Button
            className={classes.button}
            size="medium"
            variant="contained"
            disabled={!valuesCheck}
            color="primary"
            style={{ margin: `10px 20px`, borderRadius: `12px` , width:`100px`, height:`35px`,
          padding:`10px, 24px, 10px, 24px`}}
            onClick={addAttribute}

          >
          
          {/* <Check style={{width:'20px',height:'20px',paddingRight:'5px'}} /> */}
            <Typography
            style={{
              fontStyle: 'normal',
              fontWeight: '800',
              fontSize: '12px',
              lineHeight: '24px',
              textalign: 'right',
              letterSpacing: '0.4px',
              textTransform:'none'
            }}>
            
          
              Add
            </Typography>
          </Button>
        <div>
          {

            inp != "" && newVal.length ? 
            <div style={{border : "1px solid #d5d5d5", width:"230px", padding:"8px",
            }} class="dropdown-menu">
           { newVal.map((element) => {
                return (
                    <div class="dropdown-item">
                      {element.attributeValue}
                    </div>  
                  
                  )
                }) 
              }
                  </div>    
            : ""
            }
          </div>
        </div>
      </div>
      : <></>
    
    }
    
    {
      loading ? (<Loader  />) :

      rows?.length ? 
      <div style = {{marginTop:"24px", paddingLeft:"10px",position:"relative", display:"flex",flexDirection:"row", flexWrap:"wrap"}}>
      <div style={{display:"flex",justifyContent:"space-between", display:"flex",flexDirection:"row", flexWrap:"wrap", alignItems:"center", width:"1150px", marginLeft: "64px"}}>
        
      <Typography
            style={{
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '0.875rem',
              lineHeight: '24px',
              textalign: 'right',
              letterSpacing: '0.4px',
              // marginLeft:"65px"
            }}>
            
          
              Values
        </Typography>
        <Typography
            style={{
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '0.875rem',
              lineHeight: '24px',
              textalign: 'right',
              letterSpacing: '0.4px',
              // marginLeft:"135px"
            }}>
            
          
              Updated On
        </Typography>
        <Typography
            style={{
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '0.875rem',
              lineHeight: '24px',
              textalign: 'right',
              letterSpacing: '0.4px',
              // marginRight:"90px",
              marginRight:"262px"
            }}>
            
          
              Visibility
        </Typography>
      </div>
      <AttributesTable rows = {rows} setRows = {setRows} body={body} />
    </div> : (subAttrVal||attrVal) ? (<NoResultFound  />) : <></>
    }
    

  </div>:<NoAccess />}</>
    
  );
}
