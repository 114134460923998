import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function SFActivitiesCallback() {
    debugger;
    const history = useHistory();
    const tenantId = localStorage.getItem('b2bTenantId');
    useEffect(() => {
        const code = getParameterByName('code');
        console.log("Code: "+code);
        if (code) {
//             alert('here');
            fetch(`${window._env_.SF_ACTIVITIES_BULK_SYNC}/salesforce/auth/callback?code=${code}&tenantId=${tenantId}`, {
                method: 'GET',
                credentials : 'include',
                headers: {
                    "auth-token" : `${localStorage.getItem('b2bToken')}`
                }
            }).then(res => {
                history.push('/sfactivities-sync');
            })
        }
    });

    return (
        <p>SalesforceMAP Autenticated</p>
    )
}

export default SFActivitiesCallback;
// lt:3000
// developer console
// change local storage key in line 14
