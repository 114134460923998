import { blue } from '@material-ui/core/colors';
import React, { useState, Fragment } from 'react';
import './TenantSearchBar.css'
import b2Logo from '../../media/b2Logo.png'
import { Paper, Typography, makeStyles } from "@material-ui/core";
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import muiButtonBase from '@bamboobox/b2-theme/muiButtonBase';
// import { width } from '@mui/system';
import { TextField, Autocomplete, Button} from "@mui/material";
import { IconButton, InputAdornment } from '@material-ui/core';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

import { SearchOutlined } from '@material-ui/icons';

import { ThemeProvider, createTheme } from '@mui/material/styles'; 


const theme = createTheme({
    components: {
        MuiAutocomplete: {
            styleOverrides: {
              listbox: {
                '& .MuiAutocomplete-option[aria-selected="true"]': {  // works
                  background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #21DA8C`,
                },
                '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': { // works
                  background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #21DA8C`,
                },
                '& .MuiAutocomplete-option:hover': { // works
                    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #21DA8C`,
                },
                
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#21DA8C"
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#21DA8C"
                }
              }
            }
          }
    }
  });


const TenantSearchBar = (props) => {
    console.log("props in tenat",props)
    const [option, setOption] = useState();
    const [options,setOptions]=useState(props.data|| [])
    const [isEmpty, setInputState] = useState(true);
    // let options = props.data;
    // console.log(' ', options);

  


    const handleContinue = (e) => {
        setOption(option);
        console.log('optioOnConfire', option);
        props.selectedOption(option);
    };

    const handleSelect = (event, value) => {
        console.log('selected tenant', option, value);
        if(!value) {
          setOption("");
          return setInputState(true);
        }
        let flag = true;
        for (let i = 0; i < (props.data).length; i++) {
            if ((props.data[i]).toLowerCase() === value.toLowerCase()) {
                flag = false;
            }
        }
        if (flag === false) {
            setInputState(false);
        } else if (flag === true || value === '') {
            setInputState(true);
        }
        setOption(value);
    };

    const handleBack = (e) => {
        props.selectedOption(false);
    };

    return (

        <div  style={{ width: 'auto', height: 'fit-content',display:'flex',flexDirection:'column',marginTop:"58px"}}>
            <div style={{paddingBottom: "64px"}}>
            <ThemeProvider theme={theme}>
            <Autocomplete
                className="Normal"
                style={{width:"435px",marginLeft:"35px" }}
                disablePortal
                // freeSolo
                autoHighlight
                autoComplete
                onChange={handleSelect}
                id="combo-box-demo"
                options={props.data.filter(e=>e).sort()}
                // options={options.sort()}
                popupIcon={<KeyboardArrowDownRoundedIcon style={{ color: "rgba(3, 3, 3, 0.6)" }} />}
                renderInput={(params) =>
                    <TextField  {...params}
                        placeholder="Select Tenant"

                        // InputProps={{
                        //     ...params.InputProps,
                        //     startAdornment: (
                        //         <InputAdornment position="start">
                        //             <SearchOutlined style={{color:'gray'}}/>
                        //         </InputAdornment>
                        //     ),
                        // }}
                    />}
            />
            </ThemeProvider>
            <ThemeProvider theme={theme}>
            {/* <Autocomplete
                                      className="Normal"
                                        style={{width:"435px",marginLeft:"35px" }}
                                        options={options}
                                        value={option}
                                        onSelect={handleSelect}
                                        onChange={handlechange}
                                        popupIcon={<KeyboardArrowDownRoundedIcon style={{ color: "rgba(3, 3, 3, 0.6)" }} />}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="outlined"
                                            InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchOutlined style={{color:'gray'}}/>
                                                </InputAdornment>
                                            ),
                                        }}
                                          ></TextField>
                                        )}
                                      /> */}
                                      </ThemeProvider>
           </div>
            <div style={{marginLeft:'35px',cursor:'pointer'}}>
                <button className='btn1' text='Back'
               onClick={handleBack} >
                    <Typography style={{
                    fontWeight: "800",
                    fontSize: "13px",
                    lineHeight: "22px",
                    letterSpacing:"0.46px",
                    }}
                    >BACK</Typography></button>
              
                <button className={isEmpty ? 'disabled-button' : 'enabled-button'} type='button' text='Continue' 
                onClick={handleContinue} 
                style={{ 
                    borderRadius:'8px',
                    padding:'8px 16px 8px 16px',
                    border:'1px',
                    width: '203px', 
                    height: '42px',
                    backgroundColor:'rgba(33, 218, 140, 1)',
                    cursor:'pointer'
                    // marginTop:'50%' 
                    }} disabled={isEmpty} right ><Typography style={{
                    fontWeight: "800",
                    fontSize: "13px",
                    lineHeight: "22px",
                    letterSpacing:"0.46px",
                    }}
                    >CONTINUE</Typography></button>
            </div>


        </div>



    );

};

export default TenantSearchBar;